import React from "react";
import { graphql } from "gatsby";
import Header from "../components/ArticleHeader"
import Layout from '../components/layout'

export default class PostTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.markdownRemark;
    const post = postNode.frontmatter;
    const fields = postNode.fields;
    if (!post.id) post.id = slug;
    return (
      <Layout location={this.props.location}>
        <article className="post" lang={post.language || 'en'}>
          <Header title={post.title} date={post.date} draft={fields.draft} tags={post.tags} />
          <section dangerouslySetInnerHTML={{ __html: postNode.html }} />
        </article>
      </Layout>
    );
  }
}


export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        language
        date(formatString: "dddd [the] Do [of] MMMM YYYY")
        tags
      }
      fields {
        slug
        draft
      }
    }
  }
`;
