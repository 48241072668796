import { get } from "lodash"

import React from "react";
import { Link } from "gatsby"

export default class ArticleHeader extends React.Component {
  render() {
    let title = this.props.title
    let date = this.props.date
    let draft = this.props.draft
    let tags = get(this, 'props.tags')
    return (<header className='article-header'>
        { draft ? (<span className='draft-flag'/>) : null }
        <h1 className='article-title'>{title}</h1>
        <time>{date}</time>
        <div>
          {tags.map(t => (<span key={`/tag/${t}`}><Link to={`/tag/${t}`}>{t}</Link> </span>))}
        </div>
      </header>)
  }
}
